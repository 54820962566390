import { render, staticRenderFns } from "./CustomTemplateWithLeftBlock.vue?vue&type=template&id=6b68efb5"
import script from "./CustomTemplateWithLeftBlock.vue?vue&type=script&lang=js"
export * from "./CustomTemplateWithLeftBlock.vue?vue&type=script&lang=js"
import style0 from "./CustomTemplateWithLeftBlock.vue?vue&type=style&index=0&id=6b68efb5&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AcceptCookies: require('/app/src/components/layout/AcceptCookies.vue').default})
