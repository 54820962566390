
import {
  mapActions,
  mapState
} from 'pinia';
import { provide } from 'vue';
import { useHeaderStore } from '@/store/header';
import TheHeader from '@/components/layout/TheHeader.vue';
import TheBurgerMenu from '@/components/layout/TheBurgerMenu.vue';
import { usePortalApi } from '@/app/plugins/api/portal';
import {
  FLAGS,
  useFeatureFlagsStore
} from '@/store/featureFlags';

import TgButton from '@/entities/TgButton/ui/TgButton.vue';
import AcceptCookies from '@/components/layout/AcceptCookies.vue';

export default {

  name: 'Default',

  components: {
    AcceptCookies,
    TheBurgerMenu,
    TheHeader,
    TgButton
  },

  setup () {
    provide('$portalApi', usePortalApi());

    const { isEnabledFlag } = useFeatureFlagsStore();
    const hasTelegramWidgetFlag = isEnabledFlag(FLAGS.telegramWidget);

    return {
      hasTelegramWidgetFlag
    };
  },

  data () {
    return {
      isMounted: false,
      isOnboardingVisible: false
    };
  },

  head () {
    return {
      title: '«Страна Девелопмент» - Личный кабинет клиента',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: '«Страна Девелопмент» - Личный кабинет клиента'
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: 'Личный кабинет клиента «Страна Девелопмент». Квартиры и коммерческие помещения в новостройках от застройщика.'
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Личный кабинет клиента «Страна Девелопмент». Квартиры и коммерческие помещения в новостройках от застройщика.'
        }
      ],

      script: [
        // calltouch start
        {
          innerHTML: '(function(w,d,n,c){w.CalltouchDataObject=n;w[n]=function(){w[n]["callbacks"].push(arguments)};if(!w[n]["callbacks"]){w[n]["callbacks"]=[]}w[n]["loaded"]=false;if(typeof c!=="object"){c=[c]}w[n]["counters"]=c;for(var i=0;i<c.length;i+=1){p(c[i])}function p(cId){var a=d.getElementsByTagName("script")[0],s=d.createElement("script"),i=function(){a.parentNode.insertBefore(s,a)};s.type="text/javascript";s.async=true;s.src="https://mod.calltouch.ru/init.js?id="+cId;if(w.opera=="[object Opera]"){d.addEventListener("DOMContentLoaded",i,false)}else{i()}}})(window,document,"ct","om6ni2v1");'
        }
        // calltouch end
      ],

      __dangerouslyDisableSanitizers: ['script', 'noscript']
    };
  },

  computed: {
    ...mapState(useHeaderStore, [
      'isOpenedMenu'
    ])
  },

  watch: {
    $route () {
      if (this.isOpenedMenu) {
        this.setHeaderMenuState(false);
      }
    }
  },

  async mounted () {
    this.isMounted = true;
  },

  methods: {
    ...mapActions(useHeaderStore, [
      'setHeaderMenuState'
    ]),

    handleOnboardingMenu (val) {
      this.isOnboardingVisible = val;
    }
  }
};
