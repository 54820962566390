
import { defineComponent } from 'vue';
import {
  SButton,
  SIcon
} from '@strana-artw/ui-kit';
import {
  tryGetFromStorage,
  trySaveToStorage
} from '@/shared/lib/utils/storageUtils';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';

export default defineComponent({
  name: 'AcceptCookies',

  components: {
    SButton,
    SIcon
  },

  data () {
    return {
      isAccepted: false
    };
  },

  computed: {
    loggedIn () {
      return this.$auth.$state.loggedIn;
    }
  },

  mounted () {
    const localStored = tryGetFromStorage('localStorage', LOCAL_STORAGE_KEYS.COOKIES_ACCEPTED);

    if (localStored.success) {
      this.isAccepted = Boolean(localStored.value);
    }
  },

  methods: {
    handleClick () {
      const localStored = trySaveToStorage('localStorage', LOCAL_STORAGE_KEYS.COOKIES_ACCEPTED, JSON.stringify(true));

      if (!localStored.success) {
        console.error('🚀 ~ file: AcceptCookies.vue ~ handleClick ~ error', localStored.error);
      }

      this.isAccepted = true;
    }
  }
});
